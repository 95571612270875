.bassoDtailFoem {
    background-image: url("../../../assets/images/basic.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: #CCF1E5;
    padding: 50px 20px;
    height: auto;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}
.bassoDtailFoem.plnBnr
{
    height: 100vh;
}
.simNumber a {
    margin-left: 10px;
}

/* .baseBnner.bassoDtailFoem {
    height: auto;
} */
/* .    ::before
{
    position: absolute;
    background: #00000099;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;

} */

.bassoDtailFoem form {
    background: #3B3B3B;
    max-width: 550px;
    margin: 0 auto;
    padding: 30px;
    padding: 29px 50px 20px;
    position: relative;
    z-index: 9;
    border-radius: 20px;
    max-height: 90vh;
    overflow-y: auto;
}
.bassoDtailFoem form::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .bassoDtailFoem form::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .bassoDtailFoem form::-webkit-scrollbar-thumb {
    background: #fff; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .bassoDtailFoem form::-webkit-scrollbar-thumb:hover {
    background: #fff; 
  }
.gormLogo img {
    max-width: 118px;
    margin: 0 auto;
    display: table;
}
.bassoDtailFoem form h4 {
    font-size: 28px;
    color: #fff;
    text-align: center;
    font-weight: 600;
    margin: 25px 0 33px;
}
.bassoDtailFoem form p {
    color: #ffffff80;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    margin: 13px 0 22px;
}

.bassoDtailFoem .formGroup label {
    color: #fff;
    font-size: 15px;
    width: 100%;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.formGroup input {
    height: 48px;
    border-radius: 12px;
    border: 1px solid #E4E4E4;
    background: transparent;
    padding: 0 12px;
    font-size: 15px;
    width: 100%;
    color: #fff;
}
.simNumber  span.simScan {
    position: static !important;
    margin-left: 16px;
}
.simNumber {
    display: flex;
    align-items: center;
}
.formGroup select {
    height: 48px;
    border-radius: 12px;
    border: 1px solid #E4E4E4;
    background: transparent;
    padding: 0 12px;
    font-size: 15px;
    width: 100%;
    appearance: none;
    color: #fff;
}
.bassoDtailFoem .formGroup
{
    margin-bottom: 20px;
    position: relative;
}
.bassoDtailFoem .formGroup svg
{
    fill: #fff;
    color: #fff;
}
.bassoDtailFoem .formGroup input[type="submit"] {
    /* background: #03FE9D; */
    background: #2C98F0;
    /* color: #000; */
    color: #fff;
    font-weight: 600;
    margin-top: 12px;
    border: 0;
}
.bassoDtailFoem .formGroup span
{
    position: absolute;
    top: 37px;
    right: 15.1px;
    pointer-events: none;
    background: #3b3b3b;

}
.phoneNumber input {
    border: 1px solid #E4E4E4;
    width: 100%;
    background: transparent;
    border-radius: 10px;
    height: 48px;
    font-weight: 400;
    padding: 0 21px;
    font-size: 16px;
    color: #fff;
    outline: none;
}
.error {
    color: red;
    font-size: 13px;
    margin-bottom: 0px;
    margin-left: 5px;
    font-weight: 600;
}
.getStartBk {
    background: #fff;
    max-width: 550px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 30px 60px 40px;
    width: 100%;
    z-index: 9;
}
.stepHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.stepHeader img {
    max-width: 112px;
}
.stepMain img {
    margin: 0 auto;
    display: table;
}
.stepMain h3 {
    font-size: 35px;
    font-weight: 700;
    text-align: center;
    margin: 22px 0 10px;
}
.stepMain p b {
    width: 100%;
    display: table;
}
.stepMain p {
    color: #000000;
    font-size: 19px;
    text-align: center;
    line-height: 26px;
}
.stepMain button {
    background: #2C98F0;
    padding: 12px 94px;
    border: 0;
    border-radius: 10px;
    color: #fff;
    margin: 0 auto;
    display: table;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.stepMain button i {
    font-size: 11px;
    margin-left: 8px;
}
.stepMain.stepTwo h3 {
    font-size: 30px;
}
.stepMain.stepTwo .formFroup {
    margin: 13px 0 20px;
    position: relative;
}
.stepMain.stepTwo .formFroup label {
    font-size: 15px;
    margin-bottom: 2px;
}
.stepMain.stepTwo .formFroup select {
    height: 48px;
    border: 1px solid #E4E4E4;
    width: 100%;
    border-radius: 12px;
    font-size: 16px;
    font-weight: 400;
    color: #00000078;
    padding: 0 13px;
    appearance: none;
}

.stepMain.stepTwo .formFroup span {
    position: absolute;
    right: 12px;
    top: 41px;
}
.UplaodFile {
    background: #374f63;
    border: 1px dashed #fff;
    border-radius: 10px;
    height: 140px;
    position: relative;
    overflow: hidden !important;
}
.UplaodFile input {
    font-size: 0;
    background: transparent;
    border: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.bassoDtailFoem .UplaodFile span {
    background: #374f63;
    position: absolute;
    top: 0;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
ul.EmployInstruction li {
    list-style: none;
    color: #fff;
    font-size: 13px;
    width: 50%;
}
ul.EmployInstruction {
    padding: 0;
    display: flex;
}
.bassoDtailFoem .UplaodFile span p {
    margin: 0;
    color: #fff;
    max-width: 310px;
    margin: 7px auto 0;
    display: table;
    line-height: 1.3;
}
.planOuter {
    width: 100%;
    max-width: 550px;
    background: #3B3B3B;
    border-radius: 10px;
    padding: 30px;
}
.planOuter h3 {
    color: #fff;
    font-size: 30px;
    text-align: center;
    justify-content: space-between;
    display: flex;
    align-items: center;
}
.planOuter u.dataPack {
    padding: 0;
    margin: 15px 0;
    display: flex;
}
.planOuter ul.dataPack li {
    background: #000000;
    width: 100%;
    list-style: none;
    border-radius: 10px;
    /* overflow: hidden; */
    position: relative;
}
.planOuter ul.dataPack li h6 {
    font-size: 11px;
    text-align: center;
    color: #fff;
    margin: 12px 0;
}
.planOuter ul.dataPack li p {
    text-align: center;
    color: #fff;
}
.listGrop {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
}
.listGrop h6 {
    color: #fff;
    margin: 0;
    font-size: 14px;
}
.listGrop span {
    color: #fff;
    font-size: 11px;
}
.listGrop span img {
    padding-left: 4px;
    max-width: 14px
}
.listType ul {
    padding: 0;
    background: #000;
    margin: 9px 0 0;
    border-radius: 8px;
    max-height: 170px;
    overflow-y: auto;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
}
.listType ul::-webkit-scrollbar {
    width: 7px;
  }
  .listType ul li {
    font-size: 11px;
    padding: 7px 9px;
    border-radius: 0 !important;
}
  /* Track */
  .listType ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .listType ul::-webkit-scrollbar-thumb {
    background: #fff; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .listType ul::-webkit-scrollbar-thumb:hover {
    background: #fff; 
  }
.listType ul li {
    list-style: none;
    border-bottom: 1px solid #d9d9d933;
    padding: 10px 16px;
    color: #fff;
    font-size: 14px;
}
.listType ul li:last-child
{
    border: 0;
}
.planOuter button {
    background: #2C98F0;
    border: 0;
    height: 47px;
    width: 100%;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
}
.listType {
    position: relative;
    margin: 0 0 18px;
}
.planOuter ul.dataPack li h5 {
    background: #00FF9D;
    text-align: center;
    padding: 19px 0;
    font-size: 38px;
    margin: 0;
}
.planOuter ul.dataPack {
    display: flex;
    column-gap: 18px;
    padding: 0;
    margin: 22px 0 20px;
    justify-content: center;
}
.paynowScan {
    background: #000;
    border-radius: 10px;
    margin: 4px 0 20px;
    padding: 25px 0;
}
.scanCode img {
    max-width: 175px;
}
.paynowScan h4 {
    font-size: 16px !important;
    margin: 0 !important;
}
.scanCode {
    text-align: center;
    padding: 18px 0;
}
.wechat {
    text-align: center;
}
.bassoDtailFoem.baseBnner {
    padding: 120px 20px;
}
@media (max-width:1500px)
{
.baseBnner.bassoDtailFoem {
    height: auto;
} 
}
@media (max-width:1300px)
{
    .bassoDtailFoem {
        padding: 120px 20px 90px;
    }
    .bassoDtailFoem.plnBnr {
        height: auto;
    }
}

@media (max-width:767px)
{
    .bassoDtailFoem form {
        padding: 30px 20px;
    }
    .bassoDtailFoem form {
        max-height: auto;
        overflow-y: auto;
    }
    .planOuter {
        padding: 16px;
    }
    .bassoDtailFoem {
        padding: 87px 15px 82px;
        height: auto;
        background-size: cover;
    }
    .planOuter ul.dataPack li {
        width: 100%;
    }
    .planOuter ul.dataPack {
        column-gap: 0;
        padding: 0;
        flex-wrap: wrap;
        row-gap: 15px;
    }
    .planOuter ul.dataPack li h5 {
        font-size: 28px;
    }
    .planOuter h3 {
        text-align: center;
        color: #fff;
        font-size: 25px;
    }
    .baseBnner.bassoDtailFoem {
        height: auto;
        padding: 47px 15px 42px;
    }
    .getStartBk {
        background: #fff;
        max-width: 550px;
        margin: 0 auto;
        border-radius: 10px;
        padding: 20px 16px 23px;
        width: 100%;
        z-index: 9;
    }
    .stepHeader img {
        max-width: 82px;
    }
    .stepMain img {
        width: 100%;
    }
    .stepMain h3 {
        font-size: 26px;
        font-weight: 700;
    }
    .stepMain p {
        font-size: 16px;
        line-height: 26px;
    }
    .stepMain.stepTwo h3 br {
        display: none;
    }
    .stepMain.stepTwo h3 {
        font-size: 25px;
    }
    .stepMain.stepTwo .formFroup label {
        font-size: 12px;
        margin-bottom: 2px;
    }
    .stepMain.stepTwo .formFroup select {
        font-size: 14px;
    }
    .stepMain.stepTwo .formFroup span {
        top: 39px;
    }
    .bassoDtailFoem form h4 {
        font-size: 19px;
    }
    .ChangeLanguage select {
        padding: 0 6px 0 0;
        font-size: 13px;
    }
}

.ChangeLanguage select {
    background: transparent;
    border: 0;
    color: #fff;
    outline: none;
    padding: 0 13px 0 0;
}
.ChangeLanguage {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .previewContainer {
    width: 98%;
    overflow: hidden !important;
    max-width: 300px;
    margin: 6px auto;
} */

.previewImage {
  width: 100%;
  /* height: auto;
  max-height: 200px; */
  object-fit: contain;
}

.pdfPreview, .docPreview {
  padding: 20px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.pdfPreview a, .docPreview a {
  color: #007bff;
  text-decoration: underline;
}